const { REACT_APP_NODE_ENV, REACT_APP_GOOGLE_SOCIAL_LOGIN_ID } = process.env;

const env = {
  production: {
    API_END_POINT: "",
    PORT: "0000",
    GOOGLE_CLIENT_ID: REACT_APP_GOOGLE_SOCIAL_LOGIN_ID,
  },
  staging: {
    API_END_POINT: "",
    PORT: "0000",
    GOOGLE_CLIENT_ID: REACT_APP_GOOGLE_SOCIAL_LOGIN_ID,
  },
  development: {
    API_END_POINT: "https://tambola-api.indianic.dev/api/v1/",
    PORT: "9094",
    GOOGLE_CLIENT_ID: REACT_APP_GOOGLE_SOCIAL_LOGIN_ID,
  },
  local: {
    API_END_POINT: "http://localhost/api/v1/",
    PORT: "0000",
    GOOGLE_CLIENT_ID: REACT_APP_GOOGLE_SOCIAL_LOGIN_ID,
  },
};

module.exports = env[REACT_APP_NODE_ENV];
