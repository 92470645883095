import React, { Component, lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import {
  IndexRouteNames,
  DashboardRouteNames,
  ProtectedRoute,
  UnProtectedRoute,
} from "./utils/index";

const Layout = lazy(() => import("./components/layout"));
const Index = lazy(() => import("./screens/index"));
const NotFound = lazy(() => import("./screens/notFound"));

class AppRouting extends Component {
  render() {
    return (
      <React.Fragment>
        <BrowserRouter>
          <div className="AppRouting">
            <Switch>
              <Suspense fallback={<div></div>}>
                <UnProtectedRoute
                  exact
                  path={IndexRouteNames.INDEX}
                  component={Index}
                />
                <ProtectedRoute
                  exact
                  path={DashboardRouteNames.DASHBOARD}
                  component={Layout}
                />
                <ProtectedRoute
                  exact
                  path={DashboardRouteNames.CLUB}
                  component={Layout}
                />
                <ProtectedRoute
                  exact
                  path={DashboardRouteNames.CLUBDETAIL + "/:clubId"}
                  component={Layout}
                />
                <ProtectedRoute
                  exact
                  path={DashboardRouteNames.CREATEGAME + "/:clubId"}
                  component={Layout}
                />
                <ProtectedRoute
                  exact
                  path={DashboardRouteNames.GAMEDETAIL + "/:gameId"}
                  component={Layout}
                />
                <ProtectedRoute
                  exact
                  path={DashboardRouteNames.GAMESETTING + "/:gameId"}
                  component={Layout}
                />

                {/*<UnProtectedRoute
                  exact
                  path={IndexRouteNames.LOGIN_EMAIL}
                  component={Layout}
                /> */}
                <Route component={NotFound} />
              </Suspense>
            </Switch>
          </div>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

export default AppRouting;
