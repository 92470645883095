// import { Title } from "react-bootstrap/lib/Modal";

export const validationMessages = {
  //Create Club Validation Messages
  PROVIDE_CLUB_NAME: "Please enter club name",
  PROVIDE_VALID_CLUB_NAME: "Please enter valid club name",
  PROVIDE_PACKAGE: "Please select package",

  //Create Game Validation Messages
  PROVIDE_GAME_NAME: "Please enter game name",
  PROVIDE_TICKET_PRICE: "Please enter price per ticket",
  PROVIDE_MAX_TICKET: "Please enter maximum tickets",
  PROVIDE_TICKET_PURCHASE_BEFORE: "Please enter ticket can be purchased before",
  PROVIDE_SCHEDULE_AT: "Please enter schedule game details",
  PROVIDE_WINNING_COMBINATION: "Please select winning combination & prize",
  PROVIDE_PRIZE: "Please enter winning prize",
};

export const successMessages = {
  CLUB_CREATE_SUCCESS: "Club created successfully!",
  CLUB_UPGRADE_SUCCESS: "Club upgraded successfully!",
  CLUB_JOIN_SUCCESS:
    "Your request to join a club has been proceed successfully!",
  GAME_TICKETS_SUCCESS: "You have applied for tickets successfully!",
  GAME_CREATE_SUCCESS: "Game has been created successfully!",
};

export const errorMessages = {
  INTERNAL_ERROR: "An internal server error occurred",
  SOMETHING_WENT_WRONG: "Something went wrong",
  SESSTION_EXPIRED: "Your session has been expired!",
};
