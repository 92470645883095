import React from "react";
import { Route, Redirect } from "react-router-dom";

import { getJwt } from "../redux/actions";

export const requireAuth = () => {
  return getJwt() ? true : false;
};

export const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      requireAuth() ? <Component {...props} /> : <Redirect to="/" />
    }
  />
);

export const UnProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      requireAuth() ? <Redirect to="/home" /> : <Component {...props} />
    }
  />
);
