export const IndexRouteNames = {
  INDEX: "/",
};

export const DashboardRouteNames = {
  DASHBOARD: "/home",
  CLUB: "/club",
  CLUBDETAIL: "/club-detail",
  GAMEDETAIL: "/game-detail",
  CREATEGAME: "/create-game",
  GAMESETTING: "/game-setting",
};
