import React, { Component } from "react";
// import { UnderWriteRoute, CmsPageRoute } from './routes/index';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import AppRouting from "./AppRouting";

//import "react-toastify/dist/ReactToastify.css";
import "!style-loader!css-loader!react-toastify/dist/ReactToastify.css";
// import "bootstrap/dist/css/bootstrap.min.css";
//import "./assets/sass/style.scss";
import "./assets/css/style.css";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <div className="App">
            <AppRouting />
          </div>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
