import http from "./http";
import { USER_LOGOUT } from "./types";

import { consoleLog } from "./index";

const tokenKey = "tambolaUserToken";

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export const getJwt = () => {
  return localStorage.getItem(tokenKey);
};

export const setJwt = (clientToken) => {
  return localStorage.setItem(tokenKey, clientToken);
};

//logout
export const logout = (url, method, isAuthorized = false) => (dispatch) => {
  if (isAuthorized) http.setJwtAuthorization(getJwt());
  return new Promise((resolve, reject) => {
    http
      .axios({ method, url, isAuthorized })
      .then((response) => {
        if (response && response.status === 200) {
          const responseData = response.data;
          localStorage.clear();
          dispatch({ type: USER_LOGOUT, payload: {} });
          resolve(responseData);
        }
      })
      .catch((error) => {
        consoleLog("error is ", error);
        reject(error);
      });
  });
};
