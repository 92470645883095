import React from "react";
import { toast } from "react-toastify";

toast.configure();

/**********************************************************************
 @PURPOSE : We can use following function to store data in localstorage 
//Validations and 
//Messages
//*********************************************************************/

/*************************************************************************************
                                  Store Data To local Storage
  **************************************************************************************/
export const setItem = (key, value) => {
  localStorage.setItem(key, value);
};
/*************************************************************************************/

/*************************************************************************************
                             Get Data From local Storage
 **************************************************************************************/
export const getItem = (key) => {
  return localStorage.getItem(key);
};
/*************************************************************************************
                               Remove Data in local Storage
 **************************************************************************************/
export const removeItem = (key) => {
  localStorage.removeItem(key);
};
/*************************************************************************************/

/*************************************************************************************
                                 Name Validation
  **************************************************************************************/
export const validateName = (name) => {
  const pattern = new RegExp(/^[ A-Za-z0-9_@./#&+-+\w\s]{1,50}$/);
  return pattern.test(name);
};

/*************************************************************************************
                             Email Validation
  **************************************************************************************/
export const validateEmail = (email) => {
  const pattern = new RegExp(
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return pattern.test(email);
};
/*************************************************************************************/

/*************************************************************************************
                             Mobile Number Validation
  **************************************************************************************/
export const validateMobileNumber = (mobileNo) => {
  // var pattern = new RegExp(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/);
  const pattern = new RegExp(/^.{10}$/);
  // return pattern.test(mobileNo);
  return pattern.test(mobileNo);
};
/*************************************************************************************/

/*************************************************************************************
                              Character Validation
  **************************************************************************************/
export const allowChar = (e) => {
  const pattern = new RegExp(/^[a-zA-Z\s]{0,255}$/);
  return pattern.test(e);
};
/*************************************************************************************/

/*************************************************************************************
                              Number Validation
  **************************************************************************************/
export const allowNumbers = (e) => {
  const pattern = new RegExp(/^[0-9\b]+$/);
  return pattern.test(e);
};
/*************************************************************************************/
/*******************************************************************************************/

// /*************************************************************************************
//                            PinCode Validate
// **************************************************************************************/
// // for pincode number validate
// validatePincodeNumber(mobileNo) {
//     var pattern = new RegExp(/^[0-9]{5}(?:-[0-9]{4})?$/);
//     return pattern.test(mobileNo);
// },
/*************************************************************************************
                            Set success Toast Message
  **************************************************************************************/
export const showSuccessToast = (msg) => {
  toast.success(msg, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};
/*************************************************************************************/

/*************************************************************************************
                            Set Error Toast Message
  **************************************************************************************/
export const showErrorToast = (msg) => {
  toast.error(msg, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};
/*************************************************************************************/

/*************************************************************************************
                            Set WARNING Toast Message
  **************************************************************************************/
export const showWarnToast = (msg) => {
  toast.warn(msg, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};
/*************************************************************************************/

/*************************************************************************************
                            Truncate Name
  **************************************************************************************/
export const truncate = (textstring, n) => {
  if (textstring.length <= n) {
    return textstring;
  }
  let subString = textstring.substr(0, n - 1);
  return subString + "...";
};
/*************************************************************************************/

/*************************************************************************************
                            Add default user image
  **************************************************************************************/
export const addDefaultUserImage = (ev) => {
  ev.target.src = "/img/no-user-image.png";
};

/*************************************************************************************/

/*************************************************************************************
                      Capitalize First Letter of string 
  **************************************************************************************/
export const stringUcfirst = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
