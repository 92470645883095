import http from "./http";
import {
  consoleLog,
  getJwt,
  validationMessages,
  setJwt,
  SESSION_LOGOUT,
} from "./index";
import { showErrorToast, setItem } from "../../utils";

export const commonApiCall = (
  url,
  method,
  data,
  dispatchType = null,
  isAuthorized = false,
  isMultipart = false
) => (dispatch) => {
  if (getJwt()) http.setJwtAuthorization(getJwt());
  else http.removeJwtAuthorization();

  if (isMultipart) http.setMultipart();

  return new Promise((resolve, reject) => {
    http
      .axios({ method, url, data })
      .then((response) => {
        console.log(response, "consoleLog");
        if (response && response.status === 200) {
          if (dispatchType !== null) {
            const payload = { data: response.data };
            dispatch({ type: dispatchType, payload });
          }
          const responseData = response.data;
          resolve(responseData);
        } else if (
          response &&
          (response.status === 403 || response.status === 401)
        ) {
          showErrorToast(validationMessages.SESSTION_EXPIRED);
          const payload = { data: "" };
          dispatch({ type: SESSION_LOGOUT, payload });
          setJwt("");
          window.location.href = "/";
        } else {
          const responseData = response.data;
          resolve(responseData);
        }
      })
      .catch((error) => {
        // this.showErrorMessage(error);
        consoleLog("error is ", error);
        reject(error);
      });
  });
};
