// For simplicity, I changed the implementation of this module
// and removed Raven. We can always add that in the future
// and this module is the only module we need to modify.

const init = () => {};

export const log = (error) => {
  console.error(error);
};

export const consoleLog = (error, type = "") => {
  const currentDate = "[" + new Date() + "] ";
  if (
    process.env.REACT_APP_NODE_ENV === "local" ||
    process.env.REACT_APP_NODE_ENV === "development"
  )
    console.log(currentDate, error, type);
};

export default {
  init,
  log,
  consoleLog,
};
