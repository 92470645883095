import { USER_DETAILS, USER_LOGOUT, SESSION_LOGOUT } from "../actions/types";

const INITIAL_STATE = {
  userDetails: {},
};
export const user = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_DETAILS:
      return Object.assign({}, state, { userDetails: action.payload.data });
    case SESSION_LOGOUT:
      return Object.assign({}, state, {
        userDetails: {},
      });
    case USER_LOGOUT:
      return Object.assign({}, state, {
        userDetails: {},
      });
    default:
      return state;
  }
};
