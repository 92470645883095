import { combineReducers } from "redux";

import { common } from "./common";
import { user } from "./user";

// Combine all reducers into root reducer
export default combineReducers({
  common,
  user,
});
